<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "cameraRecords.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <span class="headline"></span>
    </v-toolbar>
    

    <v-container fluid>
      <v-row>
        <v-col cols="5">
          <JqxScheduler ref="myScheduler"
              :width="getWidth" :height="800" :date="defaultDate" :view="'monthView'" :resources="resources"
              :editDialog=false :enableHover=false
              :appointmentDataFields="appointmentDataFields" :views="views" :dayNameFormat="'abbr'"
              @appointmentClick="mySchedulerOnAppointmentClick($event)"
              @cellClick="mySchedulerOnCellClick($event)"
              @viewChange="mySchedulerOnViewChange($event)"
              @dateChange="mySchedulerOnDateChange($event)"
          />        
        </v-col>
        <v-col cols="7">
          <video-player  class="video-player-box"
                        ref="videoPlayer"
                        :options="playerOption"
                        :playsinline="true"
                        v-if="playerOptionReady">
          </video-player>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'
import storage from '@/api/storage'
import JqxScheduler from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxscheduler.vue'

import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player' 

export default {
  components: {
      JqxScheduler,
      videoPlayer
  },
  data: () => ({
    isAgendaView: false,
    playerOption: null,
    playerOptionReady: false,
    search: '',
    dialog: false,
    getWidth: '100%',
    // eslint-disable-next-line
    defaultDate: new jqx.date(new Date()),
    appointmentDataFields: 
    {
        from: 'start',
        to: 'end',
        id: 'id',
        description: 'description',
        location: 'location',
        subject: 'subject',
        resourceId: 'calendar'
    },
    views:
    [        
      { 
        type: 'monthView' 
      },
      {
        type: "agendaView",
        timeRuler: {
          formatString: "HH:mm:ss"
        },
        timeColumnWidth: 200,
        appointmentColumnWidth: 280
      }
    ],
    jqxDataSource: {
      dataType: 'array',
      dataFields: [
          { name: 'id', type: 'int' },
          { name: 'description', type: 'string' },
          { name: 'location', type: 'string' },
          { name: 'subject', type: 'string' },
          { name: 'calendar', type: 'string' },
          { name: 'start', type: 'date' },
          { name: 'end', type: 'date' },
          { name: 'videoKey', type: 'string' },
          { name: 'snapshotKey', type: 'string' }
      ],
      id: 'id',
      localData: [],
    }
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('scanRecord.table_terminalKey'), value: 'terminalKey', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_userName'), value: 'userName', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_reservationCode'), value: 'reservationCode', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_temperature'), value: 'temperature', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_recordTime'), value: 'recordTime', align: 'left', sortable: true},
        { text: this.$t('scanRecord.mask'), value: 'mask', align: 'left', sortable: false}
      ]
    },
    resources () {
      return {
            colorScheme: 'scheme04',
            dataField: 'calendar',
            // eslint-disable-next-line
            // source: this.jqxDataAdapter
            source: new jqx.dataAdapter(this.jqxDataSource)
        }
    },
    ...mapState({
      cameraRecords: state => state.scanner.cameraRecords,
      identityId: state => state.session.identityId
    }),
  },
  mounted: async function () {
    let oneMonthEarlier = new Date(this.defaultDate.toDate());
    oneMonthEarlier.setMonth(this.defaultDate.toDate().getMonth() - 1)
    
    let oneMonthLater = new Date(this.defaultDate.toDate());
    oneMonthLater.setMonth(this.defaultDate.toDate().getMonth() + 1)


    await this.getCameraRecords({minRecordStart: oneMonthEarlier.toISOString(), maxRecordStart: oneMonthLater.toISOString()});
    
    this.jqxDataSource.localData = this.cameraRecords
    this.$refs.myScheduler.source = new jqx.dataAdapter(this.jqxDataSource)
    this.$refs.myScheduler.showLegend = true

    this.$refs.myScheduler.beginAppointmentsUpdate()
    this.cameraRecords.forEach(cameraRecord => {
      this.$refs.myScheduler.setAppointmentProperty(cameraRecord.id, 'resizable', false)
      this.$refs.myScheduler.setAppointmentProperty(cameraRecord.id, 'draggable', false)
    })
    this.$refs.myScheduler.endAppointmentsUpdate()


  },
  methods: {
    mySchedulerOnAppointmentClick: function (event) {
      this.defaultDate = event.args.appointment.from;
    },
    mySchedulerOnCellClick: async function (event) {
      console.log("mySchedulerOnCellClick", event.args.cell.appointments)
      console.log("mySchedulerOnCellClick", event.args.cell.childNodes[0].dataset.key);
      // console.log(event.args.cell.getAttribute("data-view"));

      this.defaultDate = event.args.date;

      if (event.args.cell.childNodes[0].dataset.key) {
        console.log('cameraRecords', this.cameraRecords[parseInt(event.args.cell.childNodes[0].dataset.key)])

        let videoUrl = ''
        let snapshotUrl = ''
        if (this.cameraRecords[parseInt(event.args.cell.childNodes[0].dataset.key)].videoKey) {
          videoUrl = await storage.getObject(this.cameraRecords[parseInt(event.args.cell.childNodes[0].dataset.key)].videoKey)
        } else if (this.cameraRecords[parseInt(event.args.cell.childNodes[0].dataset.key)].snapshotKey) {
          snapshotUrl = await storage.getObject(this.cameraRecords[parseInt(event.args.cell.childNodes[0].dataset.key)].snapshotKey)
        }

        console.log('videoUrl', videoUrl);
        console.log('snapshotUrl', snapshotUrl);

        this.playerOption = {
          // videojs options
          height:800,
          muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: videoUrl || snapshotUrl
          }],
          poster: snapshotUrl || null
        };

        this.playerOptionReady = true;
      } else {
        this.playerOptionReady = false;
        this.playerOption = null;
      }
    },
    mySchedulerOnViewChange: async function (event) {
      console.log("mySchedulerOnViewChange", event.args)
      console.log("mySchedulerOnViewChange from", event.args.from.toDate().toISOString());
      console.log("mySchedulerOnViewChange to", event.args.to.toDate().toISOString());

      this.$refs.myScheduler.showLegend = true;

      if (event.args.newViewType == 'agendaView') {
        this.isAgendaView = true;
        this.$refs.myScheduler.date = this.defaultDate;
      }
      else if (event.args.newViewType == 'monthView') {
        this.isAgendaView = false;
        await this.getCameraRecords({minRecordStart: event.args.from.toDate().toISOString(), maxRecordStart: event.args.to.toDate().toISOString()});

        this.jqxDataSource.localData = this.cameraRecords;
        this.$refs.myScheduler.source = new jqx.dataAdapter(this.jqxDataSource);
      } else {
        this.isAgendaView = false;
      }

      this.playerOptionReady = false;
      this.playerOption = null;

      this.$refs.myScheduler.showLegend = true;

    },
    mySchedulerOnDateChange: async function (event) {
      console.log("mySchedulerOnDateChange date", event.args.date.toString());
      console.log("mySchedulerOnDateChange from", event.args.from.toDate().toISOString());
      console.log("mySchedulerOnDateChange to", event.args.to.toDate().toISOString());

      this.$refs.myScheduler.showLegend = true;

      await this.getCameraRecords({minRecordStart: event.args.from.toDate().toISOString(), maxRecordStart: event.args.to.toDate().toISOString()});

      this.jqxDataSource.localData = this.cameraRecords;
      this.$refs.myScheduler.source = new jqx.dataAdapter(this.jqxDataSource);
      this.$refs.myScheduler.showLegend = true;

    },
    ...mapActions({
      getCameraRecords: 'scanner/getCameraRecords'
    })
  }
}
</script>